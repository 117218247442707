import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/builds/Mondaic/infrastructure/website/src/templates/mdx_template.tsx";
import { Embed } from 'semantic-ui-react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Full-Waveform Inversion`}</h1>
    <p>{`Salvus provides a robust and extensible interface to a purpose-built
nonlinear optimization library for full-waveform inversion. By
leveraging adjoint functionality, checkpointing and GPU accelerators,
Salvus efficiently computes sensitivities with respect to a variety of
model parameters. The inversion framework provides powerful workflows,
data management and remote job execution while offering the flexibility
to interface with custom misfit and processing functions. Our portfolio
of use cases includes subsurface exploration, nuclear explosion
monitoring, continental-scale seismology, nondestructive testing, and
medical imaging.`}</p>
    <Embed id="465482262" source="vimeo" autoPlay={false} placeholder="https://vumbnail.com/465482262.jpg" iframe={{
      allowFullScreen: true
    }} mdxType="Embed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      